#root {
    display: flex;
    flex-direction: column;
}

.vacation-calendar {
    padding: 5px;
    width: 100%;
    flex-grow: 1;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: 48px !important;
}

.toolbar-search {
    display: flex;
    align-items: center;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.search-button {
    background-color: #68cf79 !important;
    margin-left: 5px !important;
}

table.calendar tbody tr td {
    padding: 0 !important;
}

.wrapper {
    height: 100%;
    width: 100%;
}

.data-not-loaded {
    height: 100%;
    width: 100%;
    opacity: 0.3;
    pointer-events: none;
}

.data-loaded {
    height: 100%;
    width: 100%;
}

.sidenav-wrapper {
    height: calc(100% - 65px);
    width: 100%;
}

.col {
    width: 1%;
}

.accept-button {
    background-color: green !important;
}

.reject-button {
    background-color: red !important;
}

.search-container {
    max-width: 500px;
    padding: 3px;
}

.search-container > * {
    margin: 2px !important;
}

.monthly-calendar-wrapper {
    display: flex;
    flex-grow: 1;
}

.p-10 {
    padding: 10px !important;
}

.p-5 {
    padding: 5px !important;
}

.pointer {
    cursor: pointer;
}

.vacation-calendar.rbc-calendar .rbc-toolbar .rbc-btn-group:nth-child(1), .vacation-calendar.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    width: 100%;
}

.vacation-calendar.rbc-calendar .rbc-toolbar .rbc-btn-group:nth-child(1) {
    z-index: 1;
}

.vacation-calendar.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    position: absolute;
}

@media (max-width: 981px) {
    .vacation-calendar.rbc-calendar .rbc-toolbar .rbc-btn-group:nth-child(1), .vacation-calendar.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        width: initial;
    }

    .vacation-calendar.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        position: initial;
    }

    .apply-calendar-filter-button-grid {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 560px) {
    body {
        -webkit-text-size-adjust: none;
    }

    .apply-calendar-filter-button-grid {
        width: 100%;
        text-align: center;
    }
}
