.dialog-buttons-container {
    justify-content: space-between !important;
}

.dialog-date-container {
    display: flex;
    justify-content: space-between
}

.input-vacation {
    font-family: unset !important;
    // margin: 10px 0 10px 0 !important;
}

#alert-dialog-title {
    width: 100%;
    padding: 10px 0px 0px 0px;
    font-size: 1.17rem !important;
}

.handle {
    cursor: move;
}

.dialog-content {
    padding: 0px 24px 8px 24px !important
}

.popover {
    width: 500px;
}

.dialog-button-profile {
    margin-left: auto !important;
    margin-right: 24px !important;
    font-size: 0.65rem !important;
    white-space: nowrap;
}

#component-error-text {
    color: red;
}

.wrapper-button {
    padding: 5px 24px !important;
}

.wrapper-data-picker {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.data-picker {
    width: 49% !important;
}

.approved-vacation-dialog {
    background-color: #A9DFBF !important;
}

.rejected-vacation-dialog {
    background-color: #F5B7B1 !important;
}

.vacation-dialog-employment-date input {
    padding-bottom: 3px;
}
