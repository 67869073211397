.filter-container {
    padding: 3px;

    .filter-item {
        min-width: 220px;
        font-size: 14px !important;
    }
}

.filter-container > * {
    margin: 2px !important;
}
