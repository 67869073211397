.profile-content {
    padding: 0 16px !important;
}

.profile-actions {
    padding: 8px 16px !important;
}

.wrapper-profile {
    width: 100%;
    height: 100%;
    padding: 15px;

    @media screen and (max-width: 1300px) {
        overflow-y: scroll;
    }
}

.groups-profile-page {
    margin-left: 16px;
}
