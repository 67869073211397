.sidenav {
    top: 54px;
}

.sidenav-item {
    height: 50px;
    padding-left: 24px !important;

    .sidenav-item-text {
        font-size: 14px !important;
        white-space: nowrap;
    }

    .sidenav-item-icon {
        color: white;
        max-width: 15px;
    }
}

.sidenav-children-wrapper {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.sidenav-wrapper {
    height: 100%;
}

.apple-wrappeer {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
