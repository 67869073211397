body {
    background-color: white;
    font-family: sans-serif;
}

table.calendar {
    width: 100%;
    border: grey 2px solid;
    border-spacing: 0;
}

table.calendar thead {
    background-color: #5A5A5A;
    color: white;
    margin-bottom: 3px;
}

table.calendar thead th {
    font-weight: lighter;
    padding: 10px 3px;
}

table.calendar thead th.bolder {
    font-weight: bold;
}

table.calendar tbody {
    font-size: 1.2em;
}

table.calendar td {
    text-align: center;
    background: #d9d9d9;
    padding: 8px;
    cursor: pointer;
    border: 1px solid gray;
    min-width: 15px;
}

table.calendar td:hover {
    opacity: 0.7;
}

table.calendar tr:last-child td {
    border-bottom: none;
}

table.calendar td.month-name {
    font-weight: bold;
    text-align: center;
    cursor: default;
    border-left: none;
    padding: 5px !important;
}

table.calendar td.month-name:hover {
    opacity: unset;
}

table.calendar td.prev-month,
table.calendar td.next-month {
    color: transparent;
    cursor: default;
    pointer-events: none;
}

table.calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 4px;
    min-width: 0;
    background-color: darkgray;
}

table.calendar td.holidays {
    font-weight: bold;
    color: red;
    text-decoration: underline;
}

table.calendar td.full-employment-day {
    background-color: #ffbbc0;
}

table.calendar td.half-employment-day {
    background-color: #ffffc6;
}

table.calendar td.free-employment-day {
    background-color: #c1dbc0;
}

table.calendar td.today {
    outline: 2px solid #000000;
    outline-offset: -2px;

    span {
        color: #1100ff;
        font-weight: bold;
        padding: 9%;
    }
}

table.calendar td.weekend {
    font-style: italic;
    font-weight: bold;
}

table.calendar td.bolder {
    font-weight: bold;
}

table.calendar td.winter.range,
table.calendar td.winter.selected {
    background-color: rgba(159, 220, 249, 1);
    font-weight: bold;
}

table.calendar td.spring.range,
table.calendar td.spring.selected {
    background-color: rgba(145, 212, 110, 1);
    font-weight: bold;
}

table.calendar td.summer.range,
table.calendar td.summer.selected {
    background-color: rgba(255, 232, 91, 1);
    font-weight: bold;
}

table.calendar td.autumn.range,
table.calendar td.autumn.selected {
    background-color: rgba(246, 151, 60, 1);
}

div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
}

div.calendar-controls div {
    display: inline;
}

div.calendar-controls .current-year {
    margin: 0 30px;
}

div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 0.8em;
    cursor: pointer;
}

div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
}


/* Demo */

div#calendar {
    position: relative;
    border-radius: 5px;
    border: 1px solid #5A5A5A;
    background-color: white;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//   div#root {
//     display: table;
//     margin: 50px auto;
//   }
h2,
h3,
h4,
h5 {
    text-align: center;
    color: #404040;
}

h1 {
    text-align: center;
    color: #B10909;
}

a {
    color: #B10909;
    font-weight: bolder;
    text-decoration: none;
}

a.demoLink {
    text-decoration: underline;
}

div.options {
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 30px;
}

div.options select {
    margin-left: 10px;
}

div.options .half {
    width: 48%;
    display: inline-table;;
}

div.options textarea {
    height: 320px;
    width: 100%;
    resize: vertical;
    padding: 5px;
}

div.options textarea.error {
    border-color: darkred;
    box-shadow: darkred 0 0 5px inset;
}

div.options .half a {
    font-size: 0.7em;
    margin-left: 10px;
}

p.interactiveDemo {
    font-style: italic;
}

p.interactiveDemo i {
    background-color: #E2E2E2;
    font-style: normal;
    font-family: monospace;
    padding: 2px 5px;
}

.yearly-calendar-wrapper {
    overflow: auto;
}

.day-number {
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 100;
    font-size: 10px;
}

.calendar-cell {
    padding: 5px 0;
}

.calendar-avatars-container {
    max-width: 20px !important;
}

.calendar-employee-avatar {
    width: 20px !important;
    height: 20px !important;
    font-size: 10px !important;
    font-weight: normal !important;
}

table.calendar tbody tr td {
    position: relative;
}
