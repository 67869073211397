/* Font */

@font-face {
    font-family: Poppins-Regular;
    src: url('../../assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../assets/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../../assets/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url('../../assets/fonts/poppins/Poppins-SemiBold.ttf');
}

/* Restyle */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.error-message {
    width: 100px;
    border-radius: 4px;
    height: auto;
    background: #c8000080;

}

body, html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

a {
    font-family: Poppins-Regular, serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #333333;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

p {
    font-family: Poppins-Regular, serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0;
}

ul, li {
    margin: 0;
    list-style-type: none;
}

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus, input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #adadad;
}

input:-moz-placeholder {
    color: #adadad;
}

input::-moz-placeholder {
    color: #adadad;
}

input:-ms-input-placeholder {
    color: #adadad;
}

textarea::-webkit-input-placeholder {
    color: #adadad;
}

textarea:-moz-placeholder {
    color: #adadad;
}

textarea::-moz-placeholder {
    color: #adadad;
}

textarea:-ms-input-placeholder {
    color: #adadad;
}

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

/* Utility */

.txt1 {
    font-family: Poppins-Regular, serif;
    font-size: 15px;
    color: #999999;
    line-height: 1.5;
}

.txt2 {
    font-family: Poppins-Regular, serif;
    font-size: 15px;
    color: #57b846;
    line-height: 1.5;
}

/* Login */

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #fff;
}

.wrap-login100 {
    width: 390px;
    background: #fff;
}

/* Form */

.login100-form {
    width: 100%;
}

.login100-form-title {
    display: block;
    font-family: Poppins-Bold, serif;
    font-size: 39px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
}

.login100-form-avatar {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.login100-form-avatar img {
    width: 100%;
}

/* Input */

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
}

.input100 {
    font-family: Poppins-SemiBold, serif;
    font-size: 18px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 52px;
    background: transparent;
    padding: 0 5px;
}

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    background: #57b846;
}

.focus-input100::after {
    font-family: Poppins-Medium, serif;
    font-size: 18px;
    color: #999999;
    line-height: 1.2;

    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0;
    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
    top: -20px;
    font-size: 15px;
}

.input100:focus + .focus-input100::before {
    width: 100%;
}

.has-val.input100 + .focus-input100::after {
    top: -20px;
    font-size: 15px;
}

.has-val.input100 + .focus-input100::before {
    width: 100%;
}


/* Button */

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    font-family: Poppins-Medium, serif;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background-color: #57b846;
    border-radius: 25px;

    box-shadow: 0 10px 30px 0 rgba(87, 184, 70, 0.5);
    -moz-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, 0.5);
    -webkit-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, 0.5);
    -o-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background-color: #333333;
    box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.5);
    -webkit-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.5);
    -o-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}


/* Alter Validate */

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;

    font-family: Poppins-Regular, serif;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome, serif;
    font-size: 16px;
    color: #c80000;

    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}

/* Login */

.login-more li {
    position: relative;
    padding-left: 16px;
}

.login-more li::before {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #cccccc;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
}
