.profile-title {
    margin-left: 15px;
    flex: 1;
}

.popover-profile {
    width: 600px;
    max-height: 500px;
    z-index: 100;
}

.groups-profile-dialog {
    display: flex;
    justify-content: center;
}
