.vacation-request-pending {
    background-color: #3b99fc;
}

.vacation-request-approved {
    background-color: #57b846;
}

.vacation-request-rejected {
    background-color: #db3737;
}

.clickable-row:hover {
    background-color: #81d4fa;
}
