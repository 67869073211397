.chips-container {
    display: block !important;
    white-space: nowrap;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-x: auto !important;
    overflow-y: hidden;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1200 !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

body {
    position: relative;
}

.selected-day {
    box-shadow: 0 0 18px black;
}
